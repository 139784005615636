(function () {
'use strict';

var TOOL_TYPES = ['OLTS', 'Inspection', 'Certification', 'ORL'];

angular.module("aerosApp").
  factory('newTestLimitModal', newTestLimitModal).
  controller("NewTestLimitModalCtrl", NewTestLimitModalCtrl).
  run(listenEvents);

listenEvents.$inject = ['newTestLimitModal', 'appDispatcher'];
function listenEvents (newTestLimitModal, appDispatcher) {
  appDispatcher.on('addToolPreset', function (e, model) {
    ~TOOL_TYPES.indexOf(model.toolType) &&
      newTestLimitModal.open(model);
  });
}

newTestLimitModal.$inject = ["$uibModal"];

function newTestLimitModal ($uibModal) {
  return {open: open};

  function open(limit) {
    return $uibModal.open({
        size: "md",
      templateUrl: "/static/templates/dashboard/project/modal/newTestLimitModal.html",
      controller: "NewTestLimitModalCtrl",
      resolve: {
        limit: function () { return limit },
        config: ["configService", function (configService) {
          return configService.getPromise();
        }]
      }
    }).result;
  }
}

NewTestLimitModalCtrl.$inject = ["$rootScope", "$scope", "$uibModalInstance", "aerosApi", "limit", "fiberTypes", "fiberModes", "$state", "PresetService", "appDispatcher", "Notification"];
function NewTestLimitModalCtrl ($rootScope, $scope, $uibModalInstance, aerosApi, limit, fiberTypes, fiberModes, $state, PresetService, appDispatcher, Notification) {

  $scope.fiberTypes = fiberTypes;
  $scope.fiberModes = fiberModes;
  $scope.limit = limit;

  (function setWatchers() {
    $scope.$watchCollection("limit", function (newValue, oldValue) {

      var thresholdPattern = /^\d{0,2}(\.\d{1,2}){0,1}$/;

      if (newValue.lossPerConnector && !thresholdPattern.test(newValue.lossPerConnector)) {
        newValue.lossPerConnector = oldValue.lossPerConnector;
      }

      if (newValue.lossPerSplice && !thresholdPattern.test(newValue.lossPerSplice)) {
        newValue.lossPerSplice = oldValue.lossPerSplice;
      }

    });

    $scope.$watchCollection("limit.lossThresholds", function (newValue, oldValue) {
      var thresholdPattern = /^\d{0,2}(\.\d{1,2}){0,1}$/;

      var isEmptySet = true;

      angular.forEach(newValue, function (value, key) {

        if (value && !thresholdPattern.test(value)) {
          newValue[key] = oldValue[key];
        }

        if (value) {
          isEmptySet = false;
        }
      });

      $scope.newProjectForm.$setValidity('wavelimits', !isEmptySet);

    });

    $scope.$watch("limit.maxLength", function (newValue, oldValue) {
      var min = 1, max = 200000;

      if (newValue && !(newValue === "" || parseInt(newValue) === newValue && newValue >= min
        && newValue <= max)) {
        $scope.limit.maxLength = oldValue;
      }
    });
  })();


  _.forEach(fiberModes, function (fiberMode) {
    if (fiberMode.name === 'Singlemode') {
      $scope.singleModeID = fiberMode.id;
    }
  });

  $scope.fiberTypeChanged = function () {
    // if ($scope.limit.lossThresholdType === 'ORLLoss' && $scope.limit.fiberType
    //   && $scope.limit.fiberType.type != $scope.singleModeID) {
    //   $scope.limit.lossThresholdType = 'None';
    // }
  };

  $scope.wavelengths = function (fiberMode) {
    if (fiberMode != null) {
      return _.find(fiberModes, {'id': fiberMode}).wavelengths;
    }
    return [];
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };

  $scope.saveTestLimit = function () {
    PresetService($state.params.toolType).
      create($state.params.id, $scope.limit).
      then(appDispatcher.emit('toolPresetUpdateSuccess')).
      catch(function(error) {
          Notification.error(error.data.message);
    }).
      then($uibModalInstance.close);
  };

  function prepareTestLimit(limit) {
    var preparedTestLimit = angular.copy(limit);
    preparedTestLimit.fiberType = preparedTestLimit.fiberType.id;

    return preparedTestLimit;
  }
  
  $scope.filterOM5 = function(type) {
	  if(type.id == 'OM5' && ($scope.limit.toolType == 'OLTS' || $scope.limit.toolType == 'Certification' || $scope.limit.toolType == 'Inspection')){
		  return false; 
	  }
      return true;
  };
}

}());
